import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';
import { LinkToVideo } from '../components/link-to-video';
import { Container } from '../components/container';
import { videos } from '../data/videos';

const ArticlesPage = () => (
  <Layout>
    <Seo title="Vidéos" description="Les dernières vidéos publiées par Anne Marie Sow." />
    <div className="py-12 mt-8 flex flex-col items-center">
      <StaticImage className="h-48 w-48 rounded-full" src="../images/maman.jpg" alt="" />

      <h1 className="mt-4 text-5xl font-extrabold">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-primary-900">
          Mes vidéos
        </span>
      </h1>

      <Container className="mt-8" maxWidth="4xl">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {videos.map((video, key) => (
            <LinkToVideo key={key} to={video.to} imageSrc={video.imageSrc} title={video.title} />
          ))}
        </div>
      </Container>
    </div>
  </Layout>
);
export default ArticlesPage;
